.paragraphs-item-front-stage {
    .container {
        padding-left: 0;
        padding-right: 0;

        position: relative;
    }
    .node-front-lead {
        position: relative;
        background: none;
        @include breakpoint(xs) {
            background: $green;
            .field-media img {
                max-width: 100%;
            }
        }
        .inner-content {
            @include breakpoint(xs) {
                position: relative;
                padding: 20px 0;
            }
            background: none;
            padding: 80px 20px 60px 20px;
            max-width: 700px;
            @include breakpoint(md) {
                padding: 80px 40px 40px 40px;
                width: 50%;

            }
            .field-category  {
                display: none;
            }
            header {
                .is-active-advertorial {
                    text-shadow: 1px 2px 5px rgba(36, 36, 36, 0.69);
                    opacity: 1;
                }
            }
            h3 {
                text-shadow: 1px 2px 5px rgba(36, 36, 36, 0.69);
                line-height: 1.2em;
                margin-botton: 10px;
            }
            h3, .field-summary, .is-active-advertorial {
                padding: 0 10px 0 25px;
                @include breakpoint(xs) {
                    padding: 0 20px;
                    margin: 0;
                    p {
                        margin-bottom: 0;
                    }
                }
            }
            .field-summary {
                @include breakpoint(xs) {
                    background: none;
                }
                background: url("../../img/hero_text.png") center center no-repeat;
                background-size: 100% 100%;
                min-height: 111px;
                padding-top: 20px;
            }
        }
    }
    .lead-aside {
        background-color: $green;
        padding-top: 20px;
        padding-bottom: 20px;
        @include breakpoint(sm) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @include breakpoint(md) {
            position: absolute;
            top: 50px;
            right: 40px;
            width: 40%;
            background-color: transparent;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .top-questions {
        @include breakpoint(xs) {
            margin-left: 10px;
            margin-right: 10px;
        }
        /*margin-top: 20px;*/
        /*margin-bottom: 20px;*/
        background: rgba(249, 249, 249, 0.91);
        border-radius: 10px;
        counter-reset: top-questions-counter;        
        .field-media, .field-category {
            display: none;            
        }
        .top-questions-header {
            font-family: $font-family-hand-written;
            font-weight: normal;
            color: #fff;
            background: url("../../img/fragen.png") center center no-repeat;
            background-size: contain;
            text-align: center;
            min-height: 45px;
            padding-top: 8px;
            font-size: 23px;
        }
        .node-article-front-lead-aside {
            margin-bottom: 0;
            clear: both;
            &:before {
                font-family: $font-family-hand-written;
                font-weight: normal;
                content: counter(top-questions-counter);
                counter-increment: top-questions-counter;
                position: absolute;
                top: 15px;
                left: 17px;                
                color: #fff;
                background: #ccc;
                width: 44px;
                height: 44px;
                border-radius: 22px;
                padding: 8px;
                text-align: center;
                font-size: 21px;
            }
            &:nth-of-type(3n+1):before {
                background: #e3a014;
            }
            &:nth-of-type(3n+2):before {
                background: #e8732a;
            }
            &:nth-of-type(3n+3):before {
                background: #236368;
            }
            padding-left: 60px;
            position: relative;
            h3 {
                margin-bottom: 0.2em;
            }
            .inner-content {

                width: 100%;
                .field-summary {
                    font-size: 14px;
                }
            }
        }
    }
    .special {
        @include breakpoint(xs) {
            margin-left: 10px;
            margin-right: 10px;
        }
        margin-top: 20px;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: rgba(249, 249, 249, 0.91);
        border-radius: 10px;
        .field-category {
            display: none;            
        }
        .node-article-front-lead-aside {
            h3 {
                margin-bottom: 0.2em;
            }
        }
        .inner-content {
            .field-summary {
                font-size: 14px;
            }
        }
    }
}