.paragraphs-item-hero-article-list {
    .container {
        background: url("../../img/bg_blog.jpg") right bottom;
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(sm) {
        padding: 20px 0;
        }
        position: relative;
    }

    .hero-thin-wrapper {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }
    .article-list-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding: 40px 20px;
        .field-article {
            @include clearfix;
            margin-bottom: 20px;
        }
    }
}