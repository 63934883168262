.node-article-front-lead-aside {
    margin-bottom: 15px;
     a {
            color: #000;
        }
    .field-media, .inner-content  {

        float: left;
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .field-media {
        width: 25%;
    } 
    .inner-content  {
        width: 75%;        
    }
    h3 {
        margin-top: 0px;
    }
    .field-media {
        margin-top: 5px;
    }
}
