


.node-article {
    &.node-teaser {
        &.field-category-745 {
            background: $yellow-bg;
            .read-more {
                color: $yellow;
            }
        }
        &.field-category-751 {
            background: $green-bg;
            .read-more {
                color: $green;
            }
        }
        &.field-category-752 {
            background: $blue-bg;
            .read-more {
                color: $blue;
            }
        }
        &.field-category-1098 {
            background: $red-bg;
            .read-more {
                color: $red;
            }
        }
        padding: 10px 15px;
        a {
            color: #000;
            .read-more {
                color: $green;
            }
        }
        a:hover {
            text-decoration: none;
            .read-more {                

                text-decoration: underline;
            }
        }
        header {
            min-height: 50px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            h3 {
                margin: 0;
            }
        }
        .inner-content {
            font-size: 15px;
            .field-category {
                margin: 15px 0px 10px;
                font-size: 13px;
                padding: 2px 10px;
            }
        }
    }
    .inner-content {
        .field-category {
            &.field-category-745 {
                background: $yellow;
            }
            &.field-category-751 {
                background: $green;
            }
            &.field-category-752 {
                background: $blue;
            }
            &.field-category-1098 {
                background: $red;
            }
            display: inline-block;
            padding: 1px 10px 1px;
            border-radius: 1em;
            color: #fff;
                margin-bottom: 8px;
            .term-icon {            
                display: inline-block;
                max-height: 1.3em;
                margin: 0 5px 0 0;
                width: 20px;
            }
        }
    }
}