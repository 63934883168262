$text_indent_left_md: 120px;
$text_indent_right_md: 80px;


@mixin standard_text_indent_left {
    @include breakpoint(md) {
        margin-left: $text_indent_left_md;
    }
}
@mixin standard_text_indent_right {
    @include breakpoint(md) {
        margin-right: $text_indent_right_md;
    }
}
@mixin standard_text_indent {
    @include standard_text_indent_left();
    @include standard_text_indent_right();
}

.main-col-center {
    @include breakpoint(sm) {
        position: relative;
        min-height: 1px;
        float: left;
        width: 70%;
    }
    @include breakpoint(md) {
        padding-left: $text_indent_left_md;
    }
    padding-right: 10px;
}
.main-col-right {
    margin-bottom: 20px;
    @include breakpoint(xs) {
        clear: both;
        margin-top: 40px;
    }
    @include breakpoint(sm) {
        position: relative;
        min-height: 1px;
        float: left;
        width: 30%;
        padding-left: 10px;
    }
    @include breakpoint(md) {
        padding-right: $text_indent_right_md;    
    }
}

html {
    font-size: 14px;    
}

img {
    @extend .img-responsive;
}
.btn {
    // no raised button
    border-top: none;
    border-right: none;
    border-left: none;    
    border-bottom-width: 2px;
    padding: 3px 12px 0px;
    font-size: $font-size-second;
    line-height: 2.07142; //29/14
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 0.14em;
}

.svg-envelope {
    @include sprite(envelope);
}
.svg-phone {
    @include sprite(phone);
}
.svg-vcard {
    @include sprite(vcard);
}

.svg-arrow-up {
    @include sprite(up-open-big);
}
.svg-arrow-right {
    @include sprite(right-open-big);
}
.svg-arrow-down {
    @include sprite(down-open-big);
}

.svg-arrow-left {
    @include sprite(left-open-big);
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);

    border: 1px solid $btn-secondary-border;
    &:hover, &:active {
        background: $btn-secondary-bg;
    }
}

.btn-dark {
    @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}

.page-header {
    border: none;
}

.leaflet-container .leaflet-marker-pane img, 
.leaflet-container .leaflet-tile-pane img, 
.leaflet-container img.leaflet-image-layer {
    width: 25px;
    height: auto;
}

.mfp-bg {

    background: #fff;
    opacity: 0.9;
}

.pager.pager-load-more li > a, .pager.pager-load-more li > span {
    line-height: 2;
    padding: 3px 70px 0px;
    text-transform: uppercase;
}

.page-header {
    margin-top: 42px;
    margin-bottom: 21px;
    @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 50px;
    }    
    h1 {
        font-size: 35px;
        line-height: 1.1743em;
        letter-spacing: 0.11em;
        margin: 30px 0 40px;
    }
}

#page-header {
    .tabs {
        @include breakpoint(sm) {
            margin-top: 75px;
        }
        @media (min-width: $grid-float-breakpoint) {
            margin-top: 40px;
        }        
    }
    .page-header ~ .tabs {
        margin-top: 0px;
    }
}

.embedded-video .player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embedded-video .player iframe,
.embedded-video .player object,
.embedded-video .player embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.dc-image-text-bottom {
    margin-bottom: 2em;
    img {
        max-width: 100%;
    }
    @include breakpoint(sm)  {
        .imgEditable {
            width: 38%;
            margin-right: 2%;
            float: left;
        }
        .textEditable {
            margin-left: 2%;
            width: 58%;
            float: right;
        }
    }
}


@include breakpoint(md)  {
    .node-full .middle-column .field-story .dc-image-text-bottom .imgEditable p {
        margin-right: 0px;
    }
}

.item-list ul.pagination > li > a, .item-list ul.pagination > li > span {
    border-radius: $pager-border-radius; 
}

.entityform {
    .form-actions {
        position: relative;
        .ajax-progress-throbber {
            position: absolute;
            top: 0;
            .ajax-throbber {
                margin: 5px;
                width: 20px;
                height: 20px;
                &.sk-circle .sk-child:before {
                    background-color: #fff;
                }
            }
        }
    }

}

.social [class*="fa fa-"] {
    background-color: $antrazit;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: auto 3px;
    width: 30px;
    font-size: 15px;
    text-align: center;
    &:focus {
        text-decoration: none;
        color: #fff;
    }
    &:hover, &:active {
        text-decoration: none;
        background-color: $gray-dark;
        color: #fff;
    }
}

.nav-pills {
    > li {
        // Active state
        &.active > a {
            &,
            &:hover,
                &:focus {
                border: 1px solid $brand-primary;
            }
        }
    }
}

.caption-text-style {
    padding: 8px;        
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 25px;
    background: #fff;
    border-bottom: 1px solid $brand-primary;
    h2, h3 {
        font-size: 16px;
    }
    p {
        font-size: 14px;
    }
}

.site-header-stage {
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    @include breakpoint(sm) {
        min-height: 320px;
    }
    @include breakpoint(lg) {
        background-size: contain;
        background-size: 100% 320px;
    }
    > .container {
        padding-top: 20px;
        position: relative;
    }
    .breadcrumb {
        color: #fff;
        a {
            color: #fff;
            &:hover,
                &:focus {
                color: #fff;
            }
        }
    }
    .page-header {

        h1 {
            color: #ffffff;
            font-size: 50px;
            font-weight: 700;
            text-shadow: 1px 2px 5px rgba(36, 36, 36, 0.69);
            text-align: left;            
            letter-spacing: .05em;
            margin: 40px 0 20px;
            word-wrap: break-word;
            @include standard_text_indent();
            @include breakpoint(sm) {
                font-size: 90px;
                margin: 70px 0 40px;
            }
        }

    }
    .tabs {
        position: absolute;
        right: 10px;
        bottom: 0;
    }
}
