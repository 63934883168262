.entity-paragraphs-item {
    .paragraphs-title {
        font-size: 35px;
        margin-top: 0px;
    }

    .stripe {
        @include breakpoint(xs) {
            display: none;
        }
        @include breakpoint(sm) {
            position: absolute;
            z-index: 10;
            left: 0;
            /*max-width: inherit;*/
            &.stripe-top {
                top: 0px;
            }
            &.stripe-bottom {
                bottom: 0px;

            }
        }
    }
}



.paragraphs-item-node-title {
    h1 {
        font-size: 32px;
        line-height: 1.4;
        letter-spacing: 0.05em;
        margin: 65px 0 55px;
        text-align: center;
        font-weight: bold;
        @include breakpoint(md) {
            margin-left: 15%;
            margin-right: 18%;
        }
    }
}

.node-type-category-page .paragraphs-item-webform {
    .field-webform {
        margin-left: 15px;
        margin-right: 15px;
        @include breakpoint(md) {
            margin-left: 15%;
            margin-right: 15%;
        }
    }
}

.paragraphs-item-header-block {
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .block-bean {
        .field-image {            
            img {
                width: 240px;
                margin: 0 auto;
            }
        }
        @include breakpoint(md) {
            display: block;
            /* float: right; */
            margin-top: -120px;
            /* margin-bottom: -30px; */
            position: absolute;
            top: 0;
            z-index: 20;
            right: 0;
        }
    }
}