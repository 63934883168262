.breadcrumb {
    font-size: 12px;
    line-height: 1.1em;
    letter-spacing: 0.14em;
    margin-top: 21px;
    margin-bottom: 0;
    background-color: transparent;
    a {
        color: #000;        
        &:hover,
            &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }
    float: right;
    @media (max-width: $grid-float-breakpoint-max) {
        display: none;
    }
}

