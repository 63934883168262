.view-page-preview-grid {
    .stripe-top {
        position: relative;
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .copyright-lead-image {
        @include sprite(copyright);
        position: relative;
        width: 20px;
        height: 20px;
        margin-top: -25px;
        margin-right: 10px;
        float: right;
        opacity: 0.5;

    }
    .article-header {
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        background: #f0f0f0;
        .breadcrumb {
            float: right;
            margin-top: 20px;
            padding: 0;
            margin-bottom: 30px;
        }
        h1 {        
            font-family: $font-family-serif;
            font-size: 32px;
            line-height: 1.4;
            letter-spacing: 0.05em;
            margin: 30px 0 45px;
            text-align: left;
            font-weight: bold;

        }
    }
    .stripe-bottom {
        margin-bottom: 35px;
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .foldable-content-header {
        color: #000;
        text-decoration: none;
        position: relative;


        &:after {
            content: " ";
            display: block;
            height: 20px;
        }
        .foldable-content-header-user-text {
            padding-right: 25px;
            span {
                color: $green;
                font-weight: bold;
            }
        }
        .plus {
            width: 21px;
            height: 17px;
            display: inline-block;

        }

        .plus:before, .plus:after {
            position: absolute;
            content: ' ';
            height: 21px;
            width: 3px;
            background-color: $green;
            transition: 300ms ease-in-out;
        }

        .plus:before, .plus:after {
            transform: rotate(90deg);
        }
        &.collapsed {
            .plus:before {
                transform: rotate(0deg);
            }
        }
        .fa {
            font-size: 28px;
            position: absolute;
            right: 0;
            top: -2px;
        }
        .fa-angle-down {
            display: none;
        }
        &.collapsed {
            .fa-angle-up {
                display: none;
            }
            .fa-angle-down {
                display: inline-block;
            }
        }
    }

    .field-widget-text-textfield label, 
    .field-widget-text-textarea label, 
    .form-type-telfield label {
        display: inline-block;
    }
    .field-name-field-first-name,
    .field-name-field-last-name,
    .field-name-field-mail {
        @include make-md-column(4);
    }
    .field-name-field-child-name {
        @include make-md-column(4);
    }
    .field-name-field-child-age {
        @include make-md-column(3);
    }
    .field-name-field-text,
    .form-item-field-legal-agreement-und,
    .form-actions {
        @include make-md-column(12);
    }


    .node-wrapper {
        position: relative;
        &:nth-of-type(4n+1) {
            .node-teaser {
                clear: both;
            }
        }
        .node-teaser {
            float: left;
            width: 25%;
        }
        .node-in-grid {
            display: none;
        }
    }

    .view-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .node-teaser {
        display: inline-block;
        vertical-align: top;
        background: #fff; 
        padding: 0;
        border-radius: 2px;
        margin-bottom: 1rem;
        position: relative;
        width: 100%;
        @include breakpoint(sm) {
            width: calc(100%/3 - (((3 - 1) * 1rem) / 3));
        }
        @include breakpoint(md) {
            width: calc(100%/4 - (((4 - 1) * 1rem) / 4));
        }
        .field-media {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .field-summary,
        .field-category,
        .read-more {
            display: none;
        }
        a:hover, a:focus {
            text-decoration: none;
        }
        header {
            min-height: 0px;
            margin-top: 1em;
            @include breakpoint(sm) {
                min-height: 75px;    
            }
        }
        h3 {
            text-align: center;
            width: 100%;
        }
        &.node-is-active-advertorial {
            .is-active-advertorial {
                text-align: center;
                margin-top: 1em;
            }
            header {
                margin-top: 0;
                min-height: 58px;
            }
        }
    }
    .node-teaser.active {
        &:after {
            bottom: 0px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: #fff;
            border-width: 24px;
            left: 50%;
            margin: -20px 0 0 -15px;

        }
    }
    .node-in-grid {
        position: relative;
        display: none;
        vertical-align: top;
        background: #008990;
        border-radius: 2px;
        margin-bottom: 1rem;
        position: relative;
        width: 100%;
        padding-bottom: 20px;
        @include breakpoint(sm) {
            padding-top: 15px;
            padding-bottom: 30px
        }
        @include breakpoint(md) {
            padding-top: 30px;
            padding-bottom: 50px;
        }
        margin-top: -1rem;
        /*        .field-image {
                    width: 50%;
                    float: left;
                    padding: 0 20px 20px;
        
                }*/
        .field-media {
            @include make-md-column(5);
            padding-left: 0;
            display: none;
            @include breakpoint(md) {
                display: block;
            }
        }
        .inner-content {
            @include make-md-column(7);
        }
        .stripe {
            position: absolute;
            &.stripe-top {
                top: 0px;
            }
            &.stripe-bottom {
                bottom: 0px;
                margin-bottom: 0px;
            }
        }
        h2 {
            text-align: left;
            font-size: 30px;

            a {
                color: #fff;
                text-decoration: none;
                &:hover, &:active {
                    color: #fff;
                    text-decoration: none;
                }
            }

        }
        .field-summary {
            color: #fff;
            font-size: 17px;
            max-width: 500px;
        }
        .field-story {
            color: #fff;
            h2, h3, h4 {
                color: #fff;
            }
            p {
                margin-bottom: 0.5em;
            }
        }
        .read-more {
            font-size: 18px;
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
        .links {
            margin: 30px 0 15px 0;
            svg {
                path {
                    fill: #008990;
                }
            }

        }
    }
    .node-in-grid.active {
        display: inline-block;
    }
}