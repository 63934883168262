$icons: (
	  sprite: (width: 830px, height: 800px, pngPath: '../sprites/sprite.png', svgPath: '../sprites/sprite.svg'),
 	bookmark: (width: 40px, height: 40px, backgroundX: 0px, backgroundY: 0px),
 	bookmark-active: (width: 40px, height: 40px, backgroundX: -50px, backgroundY: -50px),
 	copyright: (width: 16px, height: 16px, backgroundX: -100px, backgroundY: -100px),
 	down-open-big: (width: 16px, height: 8.57px, backgroundX: -126px, backgroundY: -126px),
 	envelope: (width: 20.36px, height: 16px, backgroundX: -152px, backgroundY: -145px),
 	envelope-o: (width: 20.36px, height: 16px, backgroundX: -183px, backgroundY: -171px),
 	expand: (width: 16px, height: 16px, backgroundX: -214px, backgroundY: -197px),
 	facebook: (width: 8.309999999999999px, height: 16px, backgroundX: -240px, backgroundY: -223px),
 	google-plus: (width: 16px, height: 10.18px, backgroundX: -259px, backgroundY: -249px),
 	heart: (width: 16px, height: 13.71px, backgroundX: -285px, backgroundY: -270px),
 	heart-o: (width: 16px, height: 13.71px, backgroundX: -311px, backgroundY: -294px),
 	immo-car: (width: 16px, height: 5.279999999999999px, backgroundX: -337px, backgroundY: -318px),
 	immo-chambers: (width: 16px, height: 12.219999999999999px, backgroundX: -363px, backgroundY: -334px),
 	immo-euro: (width: 14.14px, height: 16px, backgroundX: -389px, backgroundY: -357px),
 	immo-living-area: (width: 20.93px, height: 16px, backgroundX: -414px, backgroundY: -383px),
 	immo-total-area: (width: 16px, height: 15.239999999999998px, backgroundX: -445px, backgroundY: -409px),
 	info: (width: 16px, height: 16px, backgroundX: -471px, backgroundY: -435px),
 	left-open-big: (width: 8.61px, height: 16px, backgroundX: -497px, backgroundY: -461px),
 	phone: (width: 16px, height: 16px, backgroundX: -516px, backgroundY: -487px),
 	pinterest-p: (width: 16px, height: 20.8px, backgroundX: -542px, backgroundY: -513px),
 	resize-full: (width: 25px, height: 25px, backgroundX: -568px, backgroundY: -544px),
 	right-open-big: (width: 8.61px, height: 16px, backgroundX: -603px, backgroundY: -579px),
 	share-alt: (width: 40px, height: 40px, backgroundX: -622px, backgroundY: -605px),
 	share-alt-active: (width: 40px, height: 40px, backgroundX: -672px, backgroundY: -655px),
 	twitter: (width: 16px, height: 12.989999999999998px, backgroundX: -722px, backgroundY: -705px),
 	up-open-big: (width: 16px, height: 8.59px, backgroundX: -748px, backgroundY: -728px),
 	vcard: (width: 20px, height: 16px, backgroundX: -774px, backgroundY: -747px),
 	whatsapp: (width: 16px, height: 16.13px, backgroundX: -804px, backgroundY: -773px),
);
