.nav > li > a {
    padding: 8px 11px; 
    
    @media (min-width: $grid-float-breakpoint) {
        padding: 8px 11px 5px 11px;
    }
}

.logged-in .menu-link-login {
    display: none;
}

.header .menu-link-impressum {
    display: none;
}

#navbar-administration.navbar-oriented .navbar-bar {
    z-index: 1002;
}
#navbar-administration .navbar-tray {  
    z-index: 1001;
}

#logo {
    display: block;
    text-indent: -9999px;
}
/*.navbar-default.affix-top {
    #logo {
        width: 100px;
        height: 100px;

        background: url(../../logo_mobile.svg);
        background-size: contain;   
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 32px;
        width: 26px;

        @media (min-width: $grid-float-breakpoint) {
width: 250px;
    height: 120px;
            background: url(../../logo_nav.svg) no-repeat;
            background-size: 580px 164px;
            background-size: contain;
        }
    }
}
.navbar-default.affix {
    #logo {        
        background: url(../../logo_mobile.svg);
        background-size: contain;   
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 32px;
        width: 26px;
    }
}

@media (max-width: 575px) {
    .navbar-default.affix {
        padding-top: 0px !important;
    }
}*/

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
    border-color: transparent;
}

/*#main-wrapper {
    z-index: 100;
    position: absolute;
    width: 100%;
}*/
@media (min-width: $grid-float-breakpoint) {
    .navbar-vertical.navbar-tray-open {
        .navbar-default {
            left: 100px;
        } 
    }
}


.navbar-default {
    /*    position: fixed;
        top: 0;
        z-index: 1000;
        width: 100%;    */



    > .container {
        position: relative;
        @media (min-width: $grid-float-breakpoint) {
            min-height: 200px;
        }
    }



    @media (min-width: $grid-float-breakpoint) {

        background: url("../../img/strip_top_navigation.png") top right no-repeat;
        background-size: contain;
    }
    .menu-header {
        @media (max-width: $grid-float-breakpoint-max) {
            margin: 6px -15px;
            .nav {
                > li {
                    @media (min-width: $grid-float-breakpoint) {
                        margin-right: 10px;
                    }
                }
                > li > a {
                    color: $navbar-default-link-color;
                    text-transform: uppercase;
                    @media (min-width: $grid-float-breakpoint) {
                        font-weight: bold;
                    }
                    letter-spacing: 0.05em;

                    &:hover,
                        &:focus {
/*                        color: $navbar-default-link-color;
                        background-color: transparent;*/
                        
                        color: $navbar-default-link-hover-color;
                        background-color: $navbar-default-link-hover-bg;
/*                        @media (min-width: $grid-float-breakpoint) {
                            border-radius: 50px;
                        }*/

                    }
                }
                > .active > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-active-color;
                        background-color: $navbar-default-link-active-bg;
                      /*  @media (min-width: $grid-float-breakpoint) {
                            border-radius: 50px;
                        }*/
                        
                        color: #000;
                background-color: #fff;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-disabled-color;
                        background-color: $navbar-default-link-disabled-bg;
                    }
                }

            }
        }
        @media (min-width: $grid-float-breakpoint) {
            position: absolute;
            top: 0px;            
            left: 50%;

            a {
                color: #000;
            }

            .nav {
                margin-left: -50%;
                > li {
                    display: inline-block;
                    &:after {
                        content: " ";
                        display: inline-block;
                        border-right: 1px solid #000;
                        /* width: 4px; */
                        height: 17px;
                        margin-bottom: -3px;
                    }
                    &.last:after {
                        display: none;
                    }
                    a {
                        display: inline-block;
                        text-transform: uppercase;
                        font-size: 14px;
                        &:hover, &:focus {
                            background: none;
                        }
                    }
                }
            }
        }
    }

    .menu-header-social {
        /*@media (min-width: $grid-float-breakpoint) {*/
        padding-top: 10px;
        @media (min-width: $grid-float-breakpoint) {
            padding-top: 6px;
            position: absolute;
            top: 0;
            right: 0;            
        }
        .nav > li {
            display: inline-block;
            margin-right: 4px;
            @media (min-width: $grid-float-breakpoint) {
                margin-right: 2px;
            }

            a {
                background: #bababa;
                border-radius: 40px;
                width: 40px;
                height: 40px;
                text-indent: -9999px;
                white-space: nowrap;
                overflow: hidden;
                padding: 0;
                &:before {
                    color: #f1f1f1;
                    visibility: visible;
                    text-indent: 0px;
                    width: 40px;
                    height: 40px;
                    display: block;
                    /* padding: 11px 10px 10px 13px; */
                    padding-top: 8px;
                    font-size: 22px;
                    text-align: center;
                }

                &:hover, &:focus {
                    background: #bababa;
                }
                @media (min-width: $grid-float-breakpoint) {
                    width: 34px;
                    height: 34px;
                    &:before {
                        width: 34px;
                        height: 34px;
                        font-size: 18px;
                    }
                }
            }
        }
        /*}*/
    }
    .navbar-header {
        background-color: $navbar-default-bg;
        @media (max-width: $grid-float-breakpoint-max) {
            .logo-wrapper {
                width: 200px;
                display: block;
                margin: 20px;
            }
        }
        @media (min-width: $grid-float-breakpoint) {
            background-color: transparent;
            .logo-wrapper {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 270px;
                @include breakpoint(lg) {
                    width: 300px;   
                }
            }
        }
    }

}

$navbar-default-link-hover-color: #fff;
$navbar-default-link-active-color: $green;//$navbar-default-link-hover-color;
$navbar-default-link-hover-bg: transparent;//$green;
$navbar-default-link-active-bg: $navbar-default-link-hover-bg;


// Default navbar
.navbar-default {
    background-color: $navbar-default-bg;
    @media (min-width: $grid-float-breakpoint) {
        background-color: transparent;
    }
    border-color: $navbar-default-border;

    .navbar-nav {
        @media (min-width: $grid-float-breakpoint) {
            position: absolute;
            bottom: 14px;
            right: 0;
            font-size: 16px;
            @include breakpoint(md) {
                left: 29%;
            }
            @include breakpoint(lg) {
                bottom: 24px;
                left: 33%;  
            }
        }
    }

    .navbar-nav {
        > li {
            @media (min-width: $grid-float-breakpoint) {
                margin-right: 10px;
            }
        }
        > li > a {
            color: $navbar-default-link-color;
            text-transform: uppercase;
            @media (min-width: $grid-float-breakpoint) {
                font-weight: bold;
            }
            letter-spacing: 0.05em;

            &:hover,
                &:hover {
                color: $navbar-default-link-hover-color;
                background-color: $navbar-default-link-hover-bg;

/*                color: #000;
                background-color: #fff;*/

            }
            &:focus {
                @media (min-width: $grid-float-breakpoint) {
                    border-radius: 50px;
                }
            }
        }
        > .active > a {
            &,
            &:hover,
                &:hover {                
                color: $navbar-default-link-active-color;
                background-color: $navbar-default-link-active-bg;
/*                @media (min-width: $grid-float-breakpoint) {
                    border-radius: 50px;
                }*/
            }
        }
        > .disabled > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-default-link-disabled-color;
                background-color: $navbar-default-link-disabled-bg;
            }
        }
    }

    .navbar-toggle {
        border-color: $navbar-default-toggle-border-color;
        &:hover,
            &:focus {
            background-color: $navbar-default-toggle-hover-bg;
        }
        .icon-bar {
            background-color: $navbar-default-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: $navbar-default-border;
    }

    // Dropdown menu items
    .navbar-nav {
        // Remove background color from open dropdown
        > .open > a {
            &,
            &:hover,
                &:focus {
                background-color: $navbar-default-link-active-bg;
                color: $navbar-default-link-active-color;
            }
        }

        @media (min-width: $grid-float-breakpoint) {
            .open .dropdown-menu {

                box-shadow: none;
                border-radius: 14px;
                margin-top: 3px; 
                padding: 11px 0;
                right: auto;
                left: -16px;
                > li a {
                    line-height: 2em;
                }
            }
        }
        @media (max-width: $grid-float-breakpoint-max) {
            // Dropdowns get custom display when collapsed
            .open .dropdown-menu {

                > li > a {
                    color: $navbar-default-link-color;
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-hover-color;
                        background-color: $green;//$navbar-default-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-active-color;
                        background-color: $navbar-default-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-disabled-color;
                        background-color: $navbar-default-link-disabled-bg;
                    }
                }
            }
        }
    }
}

.block-covers-container {
    position: relative;
}
.block-wm-page-wm-header-covers {
    display: none;
    @include breakpoint(md) {
        display: block;
        /* float: right; */
        margin-top: -120px;
        /* margin-bottom: -30px; */
        position: absolute;
        top: 0;
        z-index: 20;
        right: 0;
        a {
            position: relative;
        }
        .field-image {
            height: 142px;
            overflow: hidden;
            img {
                width: 240px;
            }
        }
    }
}

/*.block-wm-page-wm-header-covers {
    @media (min-width: $grid-float-breakpoint) {
        position: absolute;
        bottom: -45px;
        right: 0px; 

        .block__content {
            position: relative;
            height: 120px;
            width: 220px;
            overflow: hidden;
        }

        .wm-header-covers--issue-preview {
            img {
                max-width: 100%;
            }
            list-style: none;
            margin: 0;
            @media all and (max-width: 900px) {
                margin-left: -11px; 
            }
            transition: .2s all;
            padding: 0;
            li {
                background: #ccc;
                width: 105px;
                height: 150px;
                position: absolute;
                top: 0px;
                left: 60px;
                transform-origin: 50% 100%;
                box-shadow: 0px 0px 4px rgba(black,.2);
                transition: .3s all;
                &:nth-child(1) {
                    z-index: 1;
                    transform: rotate(-20deg) translateY(20px);
                }
                &:nth-child(2) {
                    z-index: 2;
                    transform: rotate(-1deg) translateY(11px);
                }
                &:nth-child(3) {
                    z-index: 3;
                    transform: rotate(14deg) translateY(10px);
                } 
            }
        }
        .wm-header-convers--button {
                background: #e5624f;
    border-radius: 100px;
    z-index: 75;
    position: absolute;
    transform: rotate(-16deg) translateY(0px);
    width: 64px;
    height: 64px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding-top: 22px;
    top: 0;
    left: 0;
    font-family: "Permanent Marker", cursive;
    line-height: 1em;
        }

    }
}*/

.navbar-default .navbar-toggle {
    background: none;
    margin: 30px 20px 0 0;
    &:hover {
        background: none;
    }
    .icon-bar {
        transition: 300ms ease-in-out;
        background-color: $green;
        position: relative;
        width: 30px;
        height: 4px;
    }
    .icon-bar:last-child {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: -11px;
    }
    .icon-bar:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 5px;
    }
    .icon-bar:nth-child(3) {
        opacity: 0;
    }
    &.collapsed {
        .icon-bar {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);        
            top: 0;
            opacity: 1;
        }
    }
}


.not-logged-in .menu-link-hide-anonymous {
    display: none;
}

.logged-in .menu-link-hide-authenticated {
    display: none;
}

