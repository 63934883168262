.paragraphs-item-partner-list {
    /*    margin-top: 7px;
        padding: 25px 35px;*/

    &:after {
        content: " ";
        clear: both;
        display: block;
        text-align: center;
        width: 330px;
        max-width: 100%;
        border-top: 1px solid #adcd61;
        margin: 50px auto;        
    }

    h2 {
        color: #adcd61;
        font-size: 24px;
        letter-spacing: 0.05em;
        text-align: left;
    }

    .partner-list {
        @include _fg(1 1);
        align-items: center;

        img {
            height: auto;            
        }
    }
    @include breakpoint(md) {
        .partner-list {
            @include _fg(1 1 1);
        }
    }
}

.node-full {
    > .paragraphs-item-partner-list {
        > .container > * {
            @include breakpoint(md) {
                margin-left: 15%;
                margin-right: 15%;
            }
        }


    }
}

.page-node-1436 .node-unpublished {
    background-color: #fff;
}