#footer {
    margin: 0;
    @include breakpoint(sm) {
        margin: 30px 0 0 0;  
    }
    padding-bottom: 80px;
}

.footer {
    /*border-top: 2px solid $gray-dark;*/
    padding-top: 47px;
    > .container {
        position: relative;
    }

    .back-to-top {
        display: inline-block;
        display: none;
        background: #000;
        font-size: 40px;
        position: absolute;
        right: 10px;        
        top: -75px;
        padding: 0 10px;
    }

    background: #333;
    color: #fff;
    a, a:hover, a:focus {
        color: #fff;
    }

    @include breakpoint(xs) {
        h3 {
            font-size: 20px;
        }
    }


    .footer-warum {
        @include breakpoint(xs) {
            margin-bottom: 20px;
            max-width: 300px;
        }
        .logo-footer {
            margin-top: 15px;
        }
        .nav {
            margin: 15px 0 0 0px;
            @include breakpoint(md) {
                margin: 15px 0 0 20px;
            }
        }
        .nav > li > a:hover, .nav > li > a:focus {
            text-decoration: none;
            background-color: transparent;
        }

        .footer a, .footer a:hover, .footer a:focus {
            color: #fff;
        }

        .nav > li > a {
            padding: 4px 5px;
        }

    }
    .footer-social-news {
        @include breakpoint(xs) {
            margin-bottom: 20px;
        }
        @include breakpoint(sm) {
            padding-left: 30px;
        }
        .nav > li {
            display: inline-block;
            a {
                background: #9e9e9e;
                border-radius: 40px;
                width: 40px;
                height: 40px;
                text-indent: -9999px;
                white-space: nowrap;
                overflow: hidden;
                padding: 0;
                &:before {
                    color: #333;
                    visibility: visible;
                    text-indent: 0px;
                    width: 40px;
                    height: 40px;
                    display: block;
                    /* padding: 11px 10px 10px 13px; */
                    padding-top: 8px;
                    font-size: 22px;
                    text-align: center;
                }

                &:hover, &:focus {
                    background: #bababa;
                }
                @media (min-width: $grid-float-breakpoint) {
                    width: 34px;
                    height: 34px;
                    &:before {
                        width: 34px;
                        height: 34px;
                        font-size: 18px;
                    }
                }
            }
        }

    }

    .footer-magazin {
        @include breakpoint(xs) {
            margin-bottom: 20px;
        }
        a:hover, a:focus {
            text-decoration: none;
        }
        img {
            float: left;
            width: 50%;
            padding: 0 20px 10px 0;
        }   
        p {
            padding-top: 25px;
            font-size: 14px;
        }
        @include breakpoint(xs) {
            &:after {
                clear: both;
            }
        }
    }

    .footer-team {
        @include breakpoint(xs) {
            clear: both;

            margin-bottom: 20px;
            /*padding-bottom: 30px;*/
        }
    }
    @include breakpoint(xs) {
        clear: both;
        padding-bottom: 30px;
    }
}


