h1, .h1 {    
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 50px;

}

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: 2 * $line-height-computed;
    margin-bottom: $line-height-computed;
}
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: $line-height-computed;
    margin-bottom: $line-height-computed;
}

.is-active-advertorial {
    opacity: 0.6;
    margin-bottom: 2px;
    font-size: 81%;

}

p {
    margin: 0 0 $line-height-computed;
}

.pre-title {
    @extend .h1;
}

.pre-title ~ h1 {
    font-family: $font-family-sans-serif;
    color: $brand-primary;
    letter-spacing: 0.14em;
    font-size: 22px;
    line-height: 1.1em;
    margin-top: 0px;
    margin-bottom: 3 * $line-height-computed;

}

h2, .h2 {
    line-height: 1.333em;
    /*letter-spacing: 0.2em;*/        
    text-align: center;
    font-weight: bold;
}

h3, .h3 {
    line-height: 1.333em;     
    font-weight: bold;
    margin: 0.5em 0 0.5em;
}





.field-story {
    font-family : $font-family-story;
    font-size: 19px;
    line-height: 1.5em;
    letter-spacing: 0.01em;
    color: $story-text;

    a {
        font-weight: bold;
        /*color: lighten($story-text, 3%);*/
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        color: $brand-primary;
        /*font-weight: normal;*/
        text-transform: none;       
        text-align: left;
    }    

    h2, .h2 {
        font-size: 24px;
        letter-spacing: 0.05em;
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }

    h3, .h3 {
        font-size: 21px;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
        padding-left: 1em;
        li {
            padding-left: 0.2em;
        }
        li:before {
            content: "";
            color: $brand-primary;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }



    ul {                
        list-style-type: none;
        li:before {
            content: "»";
        }
    }

    // see: https://www.w3.org/Style/Examples/007/color-bullets.en.html
    ol {
        list-style: none; 
        counter-reset: li;
        li:before {
            content: counter(li) "."; 
        }
        li {
            counter-increment: li;            
        }
    }


    table th, table td {
        padding: 8px 0;
    }
    tbody {
        border-top: none;
    }
    td {
        line-height: 1.214;
        padding: 0;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        letter-spacing: 0.08em;
        border-top: none;
        border-bottom: 1px solid $gray-light;
    }

    .definition-header {
        font-family: $font-family-serif;
        line-height: 1.333em;
        font-size: 19px;
        color: $brand-primary;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 0;
        text-align: left;
    }
    .definition-content {
        padding-left: 23px;
    }
}