.node-full {

    .stage .field-image,
    .stage .field-media {
        max-width: 1200px;
        margin: 0 auto;
        img {
            @extend .img-responsive;
        }
    } 
    .stage {
        @media (max-width: $grid-float-breakpoint-max) {
            min-height: 50px;
        }


        position: relative;
        .stage-image-overlay {

            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    > .paragraphs-item-content .field-story {

        > p, > h2, > h3, > h4, > ul, > ol, > blockquote, > hr {

            @include breakpoint(md) {
                margin-left: 15%;
                margin-right: 15%;
            }
        }
        > h2, > h3, > h4 {
            clear: both;
        }
        > table {
            margin-top: 20px;
            margin-bottom: 20px;

            @include breakpoint(md) {
                margin-left: 15%;
                margin-right: 15%;
                width: 70%;
            }
        }

        figcaption {
            /*color: $gray-dark;*/
            font-size: 14px;
            line-height: 1.2857em;
            letter-spacing: 0.01em;
            padding: 8px;
            /*background: $gray-lighter;*/
        }
        .copyright {
            @include sprite(copyright);
            position: relative;
            width: 20px;
            height: 20px;
            margin-top: -25px;
            margin-right: 10px;
            float: right;
            opacity: 0.5;
        }

        .btn {
            font-family: $font-family-sans-serif;
            font-weight: normal;
        }

        ul.links.inline {
            li {
                display: inline-block;
                margin-bottom: 15px;
                &:before {
                    display: none;
                }
                a {
                    color: #89ab36;
                    border: 1px solid #89ab36;
                    font-weight: normal;
                    font-family: "Open Sans",sans-serif;
                    padding: 3px 12px 0px;
                    font-size: 14px;
                    line-height: 2.07142;
                    text-transform: uppercase;
                    font-style: normal;
                    letter-spacing: 0.14em;
                    text-align: center;
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        figure, img {
            margin-top: 4px;
        }
        img.align-left {
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        img.align-right {
            margin-left: 20px;
            margin-right: 0;            
            margin-bottom: 20px;
        }
        figure.align-left{
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        figure.align-right{
            margin-left: 20px;
            margin-right: 0;            
            margin-bottom: 20px;
        }
        @include breakpoint(xs) { 
            img.align-left, img.align-right, figure.align-left, figure.align-right {
                float: none;                
                margin-left: auto;
                margin-right: auto;
                /*display: block;*/
            }
        }

        blockquote {
            color: $brand-primary;
            font-size: 22px;
            line-height: 1.2272em;
            letter-spacing: 0.04em;
            border-left: none;
            position: relative;
            @include breakpoint(md) {
                margin-left: 20%;
                margin-right: 20%;
            }
            /*            &:before {
                            font-family: $font-family-serif;
                            display: block;
                            content: "\201C";
                            font-size: 210px;
                            margin-bottom: 0px;
                            color: #cdcdcd;
                            line-height: 0;
                            padding-top: 100px;
                            margin-bottom: -87px;
                            margin-left: -24px;
                            color: $gray-lighter;
                        }*/
            cite {
                color: $gray;
                font-size: 18px;
                line-height: 1.5em;
                display: block;
                &:before {
                    content: "\2013";
                }
                &:after {
                    content: "\2013";
                }
            }
        }
        .share-button {
            color: #fff;
            border-radius: 3px;
            padding: 4px 10px 0px;
            text-decoration: none;
            margin: 16px 0;
            font-size: 0.7em;
            display: inline-block;
            span.icon {
                @include sprite(twitter);
                display: inline-block;
                margin-bottom: -2px;
            }

            span.text {
                display: inline-block;
                padding-left: 0.5em;                
                text-transform: uppercase;
            }
        }

        .twitter-share-quote {
            background-color: #65b7d7;
            border-bottom: 2px solid #5091ab;
            @extend .share-button;
        }
    }
}