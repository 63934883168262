.block-wm-page-wm-sticky-footer {
    display: none;
    &.closeblock-processed {
        display: block;
    }
    position: fixed;
    bottom: 0;
    z-index: 30000;
    width: 100%;
    transition: .2s all;
    @media all and (max-width: 767px) {
        height: 80px; }
    @media all and (max-width: 600px) {
        height: 80px; }
    &.wm-sticky-footer--state--loading {
        transform: translateY(40px);
        pointer-events: none; } 


}


.wm-sticky-footer--bar-background {
    background: #222;
    height: 40px;
    transition: .2s all;
    pointer-events: none;
    .wm-sticky-footer--state--hidden &, .wm-sticky-footer--state--loading & {
        opacity: 0; } }

.wm-sticky-footer--bar-background, .wm-sticky-footer--content-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }


.wm-sticky-footer--content-wrapper {
    z-index: 300;
    text-align: center; }


.wm-sticky-footer--offset-wrapper {
    max-width: 1050px+160px;
    margin: 0 auto;
    @media all and (max-width: 1260px) {
        margin: 0 24px;
        padding-right: 0; } }

.wm-sticky-footer--content {
    max-width: 1050px;
    @media all and (max-width: 1150px) {
        margin-right: 40px; }
    @media all and (max-width: 900px) {
        margin-right: 20px; }
    text-align: left;
    position: relative;
    height: 40px;
    transition: .2s all;
    .wm-sticky-footer--state--hidden & {
        pointer-events: none;
        transform: translateY(40px); } }

.wm-sticky-footer--issue-preview {
    img {
        max-width: 100%;
    }
    list-style: none;
    margin: 0 {
        left: 34px; }
    @media all and (max-width: 900px) {
        margin-left: -11px; }
    transition: .2s all;
    padding: 0;
    li {
        width: 105px;
        height: 150px;
        position: absolute;
        top: -40px;
        @media all and (max-width: 900px) {
            top: -40px; }
        @media all and (max-width: 767px) {
            top: -20px;
            width: 100px;
            height: 120px; }
        transform-origin: 50% 100%;
        box-shadow: 0px 0px 4px rgba(black,.2);
        transition: .3s all;
        &:nth-child(1) {
            z-index: 200;
            transform: rotate(-20deg) translateY(20px);
            .wm-sticky-footer--wrapper:hover & {
                transform: rotate(-27deg) translateY(-5px); }
            .wm-sticky-footer--state--hidden &, .wm-sticky-footer--state--loading & {
                transform: rotate(-90deg) translateY(0px) translateX(-30px) !important; } }
        &:nth-child(2) {
            z-index: 100;
            transform: rotate(-10deg) translateY(10px);
            .wm-sticky-footer--wrapper:hover & {
                transform: rotate(-10deg) translateY(-10px); }
            .wm-sticky-footer--state--hidden &, .wm-sticky-footer--state--loading & {
                transform: rotate(-90deg) translateY(0px) translateX(-30px)  !important; } }
        &:nth-child(3) {
            z-index: 50;
            transform: rotate(5deg) translateY(0px);
            .wm-sticky-footer--wrapper:hover & {
                transform: rotate(12deg) translateY(-10px); }
            .wm-sticky-footer--state--hidden &, .wm-sticky-footer--state--loading & {
                transform: rotate(-90deg) translateY(0px) translateX(-30px) !important; } } } }

.wm-sticky-footer--cta-wrapper {
    margin-left: 170px;
    @media all and (max-width: 900px) {
        margin-left: 115px; }
    height: 40px;
    position: relative;
    display: inline-block;
    @media all and (max-width: 767px) {
        margin-left: 108px;
        margin-right: 10px; } 
}
#wm-sticky-footer--abo-cta {
    margin-top: 3px;
    padding: 5px 14px;
}



#wm-sticky-footer--abo-subtitle {
    position: absolute;
    bottom: 40px;
    background: rgba(black, .75);
    color: white;
    backdrop-filter: blur(3px);
    padding: 5px 10px 4px;
    white-space: nowrap;
    left: 0;
    z-index: 2000;
    /*font-weight: bold;*/
    transform-origin: 100% 100%;
    transform: translateX(-10px);
    opacity: 1;
    pointer-events: none;
    transition: .2s all;
    .wm-sticky-footer--wrapper:hover & {
        transform: translateX(-25px);
        opacity: 1; 
    }
    .wm-sticky-footer--state--hidden & {
        transform: translateX(-10px) !important;
        opacity: 0 !important; 
    } 
}


.closeblock .closeblock-button {
    text-indent: -9999px;
    position: absolute;
    right: 20px;
    bottom: 0;
    height: 40px;
    width: 40px;
    z-index: 60000;
    @media all and (max-width: 1150px) {
        right: 10px; 
    }
    cursor: pointer;
    transform: rotate(-90deg);
    transition: .2s all;
    &:after, &:before {
        content: '';
        width: 2px;
        height: 24px;
        background: #ccc;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -2px;
        margin-top: -12px;
        transition: .2s all; 
    }
    &:after {
        transform: rotate(45deg); 
    }
    &:before {
        transform: rotate(-45deg); 
    }
    &:hover {
        &:after, &:before {
            background: white; 
        } 
    }
}

/*.wm-sticky-footer--newsletter-wrapper {
    position: absolute;
    bottom: 4px;
    height: 32px;
    left: 350px;
    right: 120px;
    @media all and (max-width: $screen-sm) {
        display: none; 
    }
    @media all and (max-width: 1020px) {
        right: 0; 
    }
    @media all and (max-width: 900px) {
        left: 260px;
        text-align: right; 
    }
    text-align: center;
    .alert {
        padding: 5px;
    }
    form {
        position: relative;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
        @media all and (max-width: 1020px) {
            border-right: none; 
        }
        @media all and (max-width: 900px) {
            border: none; 
        }
        display: inline-block;
        padding: {
            left: 10px;
            right: 15px; 
        }
        label, .button, .form-item-mail  {
            display: inline-block;
            width: auto;
            margin: 0;
            padding: 0 {
                left: 10px;
                right: 10px; 
            }

            @media all and (max-width: 900px) {
                padding: 0 0 5px 5px;
                font-size: .9em; 
            } 
        }
        label, .button {
            height: 32px;
            margin-top: -1px
        }

        .form-item-mail {
            width: 230px;
            .form-control {
                height: 32px;
                display: inherit;
            }

        }
        label {
            color: white;
            padding-right: 4px;
            font-size: 14px;
        } 
    } 
}*/


.wm-sticky-footer--social-wrapper.footer-social-news {
    position: absolute;
    right: 0px;
    bottom: 4px;
    height: 32px;
    @media all and (max-width: 768px) {
        display: none; 
    }
    .nav > li {
        margin: 0 2px;
        a {
            background: none;
            &:before {
                color: #fff;
            }
        }
    }
}

