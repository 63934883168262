.paragraphs-item-front-ressorts {
    .container {
        background: url("../../img/uber_bg.jpg") right bottom;
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(sm) {
            /*padding: 20px 0;*/
            /*margin-top: -31px;*/
        }
        @include breakpoint(md) {
            /*margin-top: -40px;*/
        }
        @include breakpoint(lg) {
            /*margin-top: -48px;*/
        }
        position: relative;
    }
    .node-hero {
        position: relative;
    }
    .stripe-middle {
        position: relative;
        margin-top: -23px;
    }
    h2 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 0.5em;
    }    
    .term-icon-img {
        &.field-category-745 {
            .term-icon-wrapper {
                background: $yellow;
            }
        }
        &.field-category-751 {
            .term-icon-wrapper {
                background: $green;
            }
        }
        &.field-category-752 {
            .term-icon-wrapper {
                background: $blue;
            }
        }
        &.field-category-1098 {
            .term-icon-wrapper {
                background: $red;
            }
        }        
        position: relative;
        .term-icon-wrapper {
            position: absolute;            
            left: 50%;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            margin-left: -25px;
            top: 0;
            padding: 10px;
        }
        .term-icon {

        }
        .term-img {
            padding-top: 20px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .col-term {
        .view {
            margin-top: 10px;
            margin-bottom: 20px;
            padding: 10px 15px;
            background: rgba(61, 85, 42, 0.41);
            border-radius: 10px;
            &:nth-of-type(2n+1) {           
                background: none;           
            }
        }


        .node-article.node-teaser {
            background-color: transparent;
            .inner-content {
                min-height: 140px;
            }
            a:hover, a:focus {
                text-decoration: none;
            }
            .field-media {
                display: none;
            }
            .is-active-advertorial {
                color: #fff;
                text-align: center;
                opacity: 1;
            }
            header {
                min-height: inherit;
                display: block;
                color: #fff;
                text-align: center;
            }
            h3 {
                color: #fff;
                text-align: center;
            }

            .field-summary {
                text-align: center;
                color:  #0c3c16;
            }
            .field-category,
            .read-more {
                display: none;
            }
        }
    }
}