.node-article-hero {
    @include breakpoint(xs) {
            background: $dunkelgrau;
    }
    .field-image, .field-media {
        overflow: hidden;
        img {
            max-width: inherit;
            @include breakpoint(xs) {
                max-width: 100%;
            }   
        }

    }

    header {
        margin-top: 0.5em;
    }
    h3 {
        font-size: 35px;
        margin-top: 0px;
    }

    .inner-content {
        @include breakpoint(xs) {
                position: relative;
                padding: 20px 20px;
            }
        bottom: 0;
        margin-top: 0;
        padding: 80px 40px 40px 40px;
        width: 100%;
        position: absolute;
        z-index: 10;
        @include breakpoint(sm) {
        background: linear-gradient(to bottom,transparent 20%,rgba(0,0,0,0.7) 100%);
        }
        color: #fff;
    }

}

