.page-taxonomy-term {
    .term-description-wrapper {
        background: #f0f0f0;
    }
    .taxonomy-term-description {
        @include standard_text_indent();
        padding-top: 15px;
        line-height: 1.6em;
        padding-right: 30px;

    }
    .term-listing {
        .view-content {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-line-pack: start;
            align-content: flex-start;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }
        .node-teaser {
            display: inline-block;
            vertical-align: top;            
            border-radius: 2px;
            margin-bottom: 1rem;
            position: relative;
            .read-more {
                color: $green;
            }
            @media (min-width: $screen-xs) {
                width: calc(100%/2 - (((2 - 1) * 1rem) / 2));
            }
            a:hover, a:focus {
                text-decoration: none;
            }
            &:nth-of-type(4n+1) {
                background-color: $green-bg;
            }
            &:nth-of-type(4n+2) {
                background-color: $yellow-bg;
            }
            &:nth-of-type(4n+3) {
                background-color: $blue-bg;
            }
            &:nth-of-type(4n+4) {
                background-color: $red-bg;
            }
        }
    }
    .shop-listing {
        > div {
            background: #f0f0f0;
            padding: 15px;
        }
    }
}