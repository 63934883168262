.paragraphs-item-info-box {

    background: $green_bg url("../../img/info_box_bg.png") right center no-repeat;
    &.bg-green-plain {
        background: $green_bg;
    }
    padding: 20px 40px 30px;
    .container {

    }    
    .field-story {
        @include breakpoint(md) {
            margin-right: 120px;
        }
    }
    &.bg-green-plain {
        .field-story {
            @include breakpoint(md) {
                margin-right: 0;
            }
        }    
    }
}

