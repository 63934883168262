.fa.gjs-pn-btn, .fa.gjs-btnt {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    line-height: 21px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    margin-right: 5px;
    border-radius: 2px;
    cursor: pointer;
    padding: 5px;
    position: relative;
}