
.paragraphs-item-hero {
    .container {        
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(sm) {
        padding: 20px 0;
        }
        position: relative;
    }


}