.page-user {
    .breadcrumb {
        display: none;
    }
}
.page-user.not-logged-in {

    label {
        display: none;
    }
    .panel {
        border: none;
    }
    .panel-heading  {
        display: none;
    }
    .panel-body {
        padding: 0;
    }
    .form-type-checkbox label {
        display: block;
    }

    .footer {
        display: none;
    }
}

.profile h3 {
    border: none;
}

.view-bookmarks {
    margin-bottom: 2em;
    .flag-bookmarks {
        a, a:hover, a:focus, a:active {
            color: $gray-dark;
            text-decoration: none;
        }
        a:before {
            content: "x";
            display: inline-block;
            margin-right: 5px;
            font-size: 1.1em;
            font-family: arial;
            color: $gray-dark;
        }


    }

    .bookmark-wrapper {
        position: relative;
        &.unflag:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.85);
            top: 0;
            left: 0;
            z-index: 1;
        }
        &.unflag {
            .flag-bookmarks {
                display: none;
            }
        }


    }
}

.immo-bookmarks {
    .flag-bookmarks {
        position: absolute;
        top: 0;    
        right: 12px;
        background: #fff;
        z-index: 1;
        padding: 4px 10px;
    }
    .bookmark-wrapper {

        @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
            @include make-sm-column(6);
            &:nth-of-type(2n+1) {
                clear: both;
            }

        }
        @include breakpoint(md) {
            @include make-md-column(4);
            &:nth-of-type(3n+1) {
                clear: both;
            }
        }

    }
}

.show-maker-bookmarks {
    > a:after {
        content: "";
        width: 10px;
        height: 10px;
        background: $brand-primary;
        position: absolute;
        top: 8px;
        right: 2px;
        border-radius: 5px;
    }
    
    .menu-link-bookmarks {
        color: $brand-primary !important;
    }
}