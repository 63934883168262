.form-control.form-text {
    height: 37px;
    padding: 12px 12px 7px;

}


.form-control::-webkit-input-placeholder { color: $gray; }
.form-control:-moz-placeholder { color: $gray; }
.form-control::-moz-placeholder { color: $gray; }
.form-control:-ms-input-placeholder { color: $gray; }

.form-required {
    color: #000;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: .01px;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}

.form-select, .form-select:focus, .form-select:active {
    background: white url('../../img/select_sprite.svg') no-repeat;
}
[dir="ltr"] .form-select {
    padding-right: 3.51961em;
    background-position: right -5px center;
}
[dir="rtl"] .form-select {
    padding-left: 3.51961em;
    background-position: left -5px center;
}


#autocomplete {
    border: 1px solid $gray-dark;
    color: $gray-darker;
    li {
        white-space: normal;
        margin: 0;
        padding: 3px 3px 0 3px;
        &.selected {
            background: $gray-lighter;
            color: inherit;
        }
        &:last-of-type {
            padding: 3px;
        }
    }
    ul.links,
    ul.links li {
        background: none;
    }
    p {
        margin: 0;
    }
}

.field-widget-text-textfield,
.field-widget-text-textarea,
.form-type-telfield {
    label {
        display: none;
    }
}


.webform-component {
        max-width: 650px;
    label {
        color: $gray-dark;
        font-weight: normal;
    }
    .form-type-checkbox {
        label {
            color: $text-color;
        }
    }
    .form-required {
        color: $brand-primary;
    }
}

.page-node-done, .page-node.node-type-webform {
    #content {
        @include make-container();
    }
    .webform-confirmation ~ .links {
        display: none;
    }
}