.paragraphs-item-container {
    padding-bottom: 50px;
}

.field-subtitel {
    margin-bottom: 37px;
}

.mfp-content {
    .royal-slider-gallery {
        background: #fff;
        padding: 50px 0 10px;
        .mfp-close {
            font-size: 50px;
        }
    }
}

.mfp-content {
    .rsImg {
        // somehow the images get margin in popup
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
}

.cta-banner {
    position: relative;
    min-height: 300px;
    /*    margin-bottom: 50px;
        @media (min-width: $screen-sm) {
            margin-bottom: 0;
        }*/
}

.cta-banner .caption-text {
    .container {
        @extend .caption-text-style;
    }
    position: absolute;

    /*    bottom: -30px;
    
        @media (min-width: $screen-sm) {*/
    bottom: 30px;
    /*}*/
}


.paragraphs-item-1639,
.paragraphs-item-container-action-boxes {
    .entity-paragraphs-item {
        @media (max-width: 600px) {
            margin: 0px 15px 30px;
        }
        @media (max-width: $screen-sm-max) {
            margin: 0px auto 30px;
            max-width: 370px;
            &:last-of-type {
                clear: both;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .paragraphs-item-gallery {
        .field-media {
            margin-bottom: 40px;
        }        
    }
}

.paragraphs-item-container-grid {
    padding-bottom: 20px;
    .caption-text {
        position: relative;
        margin-bottom: 30px;
        .field-text {
            @extend .caption-text-style;
            position: absolute;
            bottom: -15px;
            left: 35px;
            max-width: 370px;
        }
    }
}

.navbar-administration .site-header {
    margin-top: 39px;
}

.more-content-wrapper {
    h2 {
        font-size: 22px;
        letter-spacing: 0.05em;
        margin-bottom: 2em;
    }
}

.main-col-right {
    .shopify-buy-frame {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
}


.view-wm-search {
    .view-header {
        display: none;        
    }

    .views-exposed-form {
        text-align: center;
        margin-bottom: 40px;
        .views-exposed-widgets {
            display: inline-block;
        }
    }

    .views-submit-button {
        margin-top: 0;
        input.form-submit {
            margin-top: 0;
            height: 37px;
        }
    }

    .view-content {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .node-article-front-lead-aside {
        margin-bottom: 25px;
    }

}

.gjs-block.fa-home {
    display: none;
}
