.page-node.node-type-article {

}

.node-article-full {
    .article-top {
        display: relative;
        position: relative;
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .stripe-top {
        position: absolute;
        top: 0;
    }
    .stripe-middle {
        position: absolute;
        bottom: 0;
    }
    .advertorial {
        background: url(../../img/fragen_form_field_bg.png) center center no-repeat;
        background-size: 100% 100%;
        padding: 4px 30px;
        position: absolute;
        top: 78px;
        left: 36px;
        font-family: "Permanent Marker", cursive;
        font-size: 22px;

    }
    .copyright-lead-image {
        @include sprite(copyright);
        position: relative;
        width: 20px;
        height: 20px;
        margin-top: -25px;
        margin-right: 10px;
        float: right;
        opacity: 0.5;

    }
    .article-header {
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        background: #f0f0f0;
        .breadcrumb {
            float: none;
            margin-top: 20px;
            padding: 0;
            margin-bottom: 30px;
        }
        .is-active-advertorial {
            float: right;
            margin-top: -47px;
        }
        .field-dateline {
            color: $gray-dark;
            font-size: 17px;
            line-height: 1.16em;
            letter-spacing: 0.14em;
            margin: 30px 0;
            text-align: center;
            text-transform: uppercase;          
            /*border-top: solid 2px $brand-primary;*/
            padding-top: 2 * $line-height-computed;
            margin-top: -2px;
            position: relative;
        }
        h1 {        
            font-size: 32px;
            line-height: 1.4;
            letter-spacing: 0.05em;
            margin: 30px 0 45px;
            text-align: center;
            font-weight: bold;

        }
        .field-summary {
            font-size: 19px;
            line-height: 1.4em;
            letter-spacing: 0.01em;    
            margin: 0 0 40px 0;
            text-align: center;
        }
        @include breakpoint(md) {
            /*            figure {
                            float: right;
                            width: 40%;
                            box-shadow: 12px 12px 32px 0px #999;
                            margin-bottom: 20px;
                            border: 5px solid #62b3de;
                            figcaption {
                                text-align: center;
                                margin-top: 25px;
                                font-size: 15px;
                            }
                        }*/
            .breadcrumb,
            .field-dateline, 
            h1, 
            .field-summary,
            .author-line,
            .article-footer {
                margin-left: $text_indent_left_md+50px;
                margin-right:  $text_indent_right_md+50px;
            }

            .field-summary {
                margin-left: $text_indent_left_md+150px;
                margin-right:  $text_indent_right_md+150px;                
            }
        }
    }
    .stripe-bottom {
        margin-bottom: 35px;
        max-width: 1200px;    
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .main-col-center {
        @include breakpoint(md) {
            width: 78%;
            padding-right: 7%;
        }
    }


    .author-line {
        /*background: url("../../img/author_line_bg.png") center center no-repeat;*/
        background-size: 100% 100%;
        text-align: center;
        /*padding: 13px 40px 13px 45px;*/
        /*margin-bottom: 30px;*/
        /*margin-left: -20px;*/
        + div {
            clear: both;
        }
        .field-category {
            display: inline-block;
            width: 36px;
            height: 36px;
            border-radius: 20px;
            text-align: center;
            padding: 6px 0 0 6px;
            margin-right: 15px;
            /*margin-bottom: 5px;*/
            &.field-category-745 {
                background: $yellow;

            }
            &.field-category-751 {
                background: $green;

            }
            &.field-category-752 {
                background: $blue;

            }
            &.field-category-1098 {
                background: $red;
            }
            .term-icon {
                display: inline-block;
                max-height: 1.3em;
                margin: 0 5px 0 0;
                width: 18px;
            }
            span {
                display: none;
            }
        }


        .author {

        }
        .date {
            margin-right: 15px;
        }
        .comments-count:before{
            /*color: #fff;*/
        }
    }

    .main-col-center .paragraphs-item-content {

        .field-story > p:first-of-type:first-letter {    
            /*font-family: $font-family-hand-written;*/

            float: left;
            font-size: 54px;
            line-height: 50px;
            /* padding-top: 0px; */
            /* padding-right: 18px; */
            /* padding-left: 13px; */
            color: #fff;
            background-color: $green;
            display: block;
            /* width: 44px; */
            /* height: 44px; */
            border-radius: 100px;
            padding: 12px 15px 4px;
            text-align: center;
            /* min-width: 50px; */
            margin: -5px 10px 5px -10px;

        }
        ~ .paragraphs-item-content {

            .field-story > p:first-of-type:first-letter {    
                /*font-family: $font-family-hand-written;*/

                float: none;
                font-size: inherit;
                line-height: inherit;
                /* padding-top: 0px; */
                /* padding-right: 18px; */
                /* padding-left: 13px; */
                color: inherit;
                background-color: inherit;
                display: inherit;
                /* width: 44px; */
                /* height: 44px; */
                border-radius: 0;
                padding: inherit;
                text-align: inherit;
                /* min-width: 50px; */
                margin: inherit;

            }
        }
    }

    .field-story {

        figure, img {
            margin-top: 4px;
        }
        img.align-left {
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        img.align-right {
            margin-left: 20px;
            margin-right: 0;            
            margin-bottom: 20px;
        }
        figure.align-left{
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        figure.align-right{
            margin-left: 20px;
            margin-right: 0;            
            margin-bottom: 20px;
        }
        @include breakpoint(xs) { 
            img.align-left, img.align-right, figure.align-left, figure.align-right {
                float: none;                
                margin-left: auto;
                margin-right: auto;
                /*display: block;*/
            }
        }

        figcaption {
            /*color: $gray-dark;*/
            font-size: 14px;
            line-height: 1.2857em;
            letter-spacing: 0.01em;
            padding: 8px;
            /*background: $gray-lighter;*/
        }
        .copyright {
            @include sprite(copyright);
            position: relative;
            width: 20px;
            height: 20px;
            margin-top: -25px;
            margin-right: 10px;
            float: right;
            opacity: 0.5;
        }

        blockquote {
            background: $green_bg url("../../img/quote_bg.png") center center no-repeat;
            background-size: auto 80%;
            border: none;
            font-family: $font-family-serif;
            font-size: 25px;
            line-height: 1.6em;
            font-weight: bold;
            text-align: center;
            padding: 40px 20px;
            cite {
                font-size: 18px;
                font-weight: normal;
            }
            .twitter-share-quote {
                display: none;
            }
        }
    }



    .article-footer {
        text-align: center;
        /*background: url("../../img/hero_text.png") center center no-repeat;*/
        background-size: 100% 100%;
        /*margin: 30px 0 40px;*/
        .share-buttons {
            padding: 20px 30px 20px 40px;

            .comment-add {
                display: none;
            }
        }
    }

}

.node-article-full, .page-taxonomy-term {
    .main-col-right {
        @include breakpoint(xs) {
            padding-top: 25px;
            margin: 40px -15px 20px -15px;

        }
        @include breakpoint(md) {
            width: 22%;
        }
        background: $lichtgrau;
        padding: 0;

        h2 {
            font-family: $font-family-hand-written;
            font-weight: normal;
            color: #fff;
            background: url("../../img/fragen.png") center center no-repeat;
            background-size: contain;
            text-align: center;
            min-height: 45px;
            padding-top: 8px;
            font-size: 23px;
            margin: 15px 2px 13px 4px;
        }
        .node-article.node-teaser {
            margin-right: 5px;
            margin-left: 5px;
            margin-bottom: 40px;
            background: none;
            header {
                min-height: inherit;
            }
            @include breakpoint(xs) {
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }    
}

.a2a_svg, .a2a_count { 
    padding: 2px;
    border-radius: 30px !important; 
}

a.a2a_button_whatsapp {
    display: none;
    @include breakpoint(xs) {
        display: inline-block;
    }

}
/*
html.touch .node-article-full .share-buttons a.a2a_button_whatsapp {
    display: block;
}*/

.royal-slider-gallery {
    @include breakpoint(xs) {
        margin-left: -20px;
        margin-right: -20px;
    }
    .field-media {
        position: relative;
        direction: ltr;
        max-width: 1200px;
        margin: 0 auto;
        > * {
            float: left;
        }
        &.rsAutoHeight {
            height: auto;
        }
        &.rsFullscreen {
            position: fixed !important;
            height: auto !important;
            width: auto !important;
            margin: 0 !important;
            padding: 0 !important;
            z-index: 2147483647 !important;
            top: 0 !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
        }
        .rsSlide.rsFakePreloader {
            opacity: 1 !important;
            -webkit-transition: 0s;
            -moz-transition: 0s;
            -o-transition:  0s;
            transition:  0s;
            display: none;
        }
    }
    .rsContainer {
        img {
            @extend .img-responsive;
        }
    }
    .field-media {
        border-top: 10px solid $green;
        margin-bottom: 30px;
    }
    .rsOverflow {
        border-left: 10px solid $green;
        border-right: 10px solid $green;
    }
    .rsArrow {
        background-color: $green;
        display: block;
        position: absolute;
        position: absolute;
        padding-top: 12px;
        color: #blue;
        width: 40px;
        height: 40px;
        text-align: center;
        top: 50%;
        margin-top: -20px;
        border-radius: 50px;
    }
    .rsArrowLeft{
        left: 10px;
        .rsArrowIcn {
            @include sprite(left-open-big);
        }
    }
    .rsArrowRight {
        right: 10px;
        .rsArrowIcn {
            @include sprite(right-open-big);
        }
    }
    .rsFullscreenBtn {
        /*background-color: #000;*/
        display: block;
        position: absolute;
        top: 10px;
        right: 0;
        /*display: none;*/        
        a {
            padding: 20px;
        }
        .rsFullscreenIcn {
            @include sprite(resize-full);
            margin-bottom: -10px;
        }
    }
    .rsNav {
        margin-top: -80px;
        width: 100%;
        display: none;
        /*            margin-left: 50px;
                    margin-right: 50px;*/
        @include breakpoint(sm) {
            .rsThumb {
                background: #fff;
                img {
                    opacity: 0.5;
                }
                &.rsNavSelected {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
        @include breakpoint(xs) {
            margin-top: -30px;
            margin-left: 20px;
            margin-right: 20px;
            .rsThumb {
                .rsTmb {
                    height: 10px;
                    border-bottom: 2px solid $brand-primary;
                    width: 25px;                
                    margin-right: 5px;
                    margin-bottom: 10px;
                    img {
                        height: 0;
                    }

                }
                &.rsNavSelected {
                    .rsTmb {
                        border-bottom: 2px solid #fff;
                    }
                }
            }

        }

    }

    .rsGCaption {
        border-top: 10px solid $green;
    }
    .rsCaption {
        font-family: $font-family-story;

        /*color: $gray-dark;*/
        font-size: 15px;
        font-weight: bold;
        line-height: 1.2857em;
        letter-spacing: 0.01em;
        padding: 10px;
        /*background: $gray-lighter;*/
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 35px;
        text-align: center;
        margin: 7px 0;
        .counter {
            float: left;
            color: $brand-primary;
            margin-right: 5px;

        }
    }
    .copyright {
        @include sprite(copyright);
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        opacity: 0.5;
    }
}


.comment-wrapper {
    .title {
        text-align: left;
        font-size: 22px;
        letter-spacing: 0.05em;
    }
    .comment-section {
        margin-bottom: 30px;
    }
    .alert {
        clear: both;
    }
    .comment-date {
        color: $gray;
        margin-bottom: 15px;
    }
    .comment-body .user-picture img, .comment-user-picture img {
        width: 100px;
        height: 100px;
    }
    .comment-user-picture {
        float: left;
        width: 100px;
    }
    .field-name-comment-body {
        float: right;

    }
    .form-type-textfield label {
        display: none;
    }
    .help-block {
        color: $gray;
    }

    .form-textarea-wrapper {
        textarea {
            min-height: 100px;
        }
    }

    .form-actions {
        &:before {
            content: " ";
            display: block;
            clear: both;
            height: 20px;
        }
        #google_recaptcha_comment_node_article_form {
            margin-bottom: 20px;
        }
        .btn {
            padding-left: 30px;
            padding-right: 30px;
        }
        .ajax-progress-throbber {
            margin-top: -20px;
            margin-left: -40px;
            .ajax-throbber {
                margin: 5px;
                width: 20px;
                height: 20px;
                &.sk-circle .sk-child:before {
                    background-color: #fff;
                }
            }
        }
    }
}
