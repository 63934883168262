.paragraphs-item-front-shop {
    .container-about {
        background: $green;        
        background: url("../../img/uber_bg.jpg") right bottom;
        position: relative;
        padding-bottom: 50px;

        > h2 {
            font-family: $font-family-sans-serif;
            font-size: 60px;
            color: #fff;
            font-weight: bold;
            img {
                display: inline-block;
                height: 1em;
                margin-top: -0.3em;
            }
        }
    }
    .welcome-team {
        .page-title {
            display: block;
            h1 {
                display: inline;
            }            
        }
        .h1, h1 {
            color: #fff;
            font-weight: bold;
            margin-top: 10px;
        }
        img {
            margin-right: auto;
            margin-left: auto;
        }
        p {
            text-align: center;
        }
        margin-bottom: 30px;
    }
    .questions-form {
        background: url("../../img/fragen_form_bg.png") center center no-repeat;
        background-size: 110% 100%;
        @include breakpoint(sm) {
            background-size: 93% 100%;
        }
        padding: 20px 6%;
        text-align: center;
        color: #fff;
        h3 {            
            color:  #dfbc3b;
            font-size: 30px;
            font-weight: bold;
        }
        .block-views-exp-wm-questions-page {
            .views-exposed-form {
                .views-exposed-widgets {
                    @include breakpoint(sm) {
                        margin: 10px 30px;
                    }
                }
            }
        }

        padding-bottom: 25px;
        margin-bottom: 35px;
    }
    .shop-product {
        .shopify-buy-frame {
            margin-left: auto;
            margin-right: auto;

        }
        padding-bottom: 25px;
        
    }
    
    .container-shop {   
        position: relative;
        padding-bottom: 50px;

        > h2 {
            font-size: 60px;
            /*color: #fff;*/
            font-weight: bold;
            margin-bottom: 1em;
        }
    }
}


.block-views-exp-wm-questions-page
, .block-views-exp-wm-unterkuenfte-page
, .block-views-exp-wm-ausfluege-page {
    .views-exposed-form {
        .views-exposed-widgets {
            background: url("../../img/fragen_form_field_bg.png") center center no-repeat;
            background-size: 100% 100%;    
            /*            height:30px;
                        width:200px;*/
            padding:10px;
            position: relative;
            display: flex;
            margin: 10px 0px;

            label {
                display: none;
            }

            input[type="text"] {
                /*background:rgba(0,0,0,0);*/
                border:1px solid #fff;
                color:#000;
                padding: 8px;
                width: 100%;
                box-shadow: none;
            }
            .views-widget-filter-search_api_views_fulltext {
                margin-top: 0px;
                padding: 0;
                float: left;
                display: inline-block;
                flex-grow: 2;
            }
            .views-submit-button {
                margin: 0;
                padding: 0;
                display: inline-block;
                /*                position: absolute;
                                right: 0;*/
                .form-submit {
                    margin-top: 0;
                    background: url("../../img/fragen_btn_bg.png") center center no-repeat;
                    background-size: 100% 100%;    
                    border: none;
                    font-size: 18px;
                    margin: 0 15px 0 5px;
                    padding-bottom: 3px;
                    padding-top: 0px;
                }
            }
            .live-result-search-item {
                text-align: left;
                padding: 5px;
                a {
                    color: #000;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        margin-bottom: 30px;
    }
}